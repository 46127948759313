'use client';

// imports
import { useState } from 'react';
import { userProfile } from '@/lib/atoms/profile';
import { useAtom } from 'jotai';
import Link from 'next/link';

// lib files
import { ProfileData } from '@/lib/types/api/profile-data';
import { VideoClass, VideoTypeEnum } from '@/lib/types/api/video';
import { useHasMounted } from '@/lib/hooks';
import { formatVideoMetadata } from '@/lib/helpers/format-video-metadata';

// components
import ITSImage from '@/components/ITSImage/ITSImage';
import LinkButton from '@/components/Button/LinkButton';
import KabobMenu from '@/components/KabobMenu/KabobMenu';
import MezzanineVideoInitializer from '@/components/MezzanineVideoInitializer/MezzanineVideoInitializer';
import MyListButton from '@/components/Button/MyList/MyListButton';
import { StationData } from '@/lib/types/api/stations-data';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer';

// svgs
import CompassRose from '/public/svg/compass-rose.svg';
import PlayIcon from "@/public/svg/play.svg";
import ArrowRightIcon from '@/public/svg/arrow-right.svg';

// styles
import styles from './VideoHero.module.scss';
interface VideoHeroProps {
  video: VideoClass;
  isSVP: boolean;
  depIsPassportMember?: boolean;
  stationData?: StationData;
  profileStub?: ProfileData;
  displayAsPassport?: boolean;
}
const VideoHero: React.FC<VideoHeroProps> = props => {
  const hasMounted = useHasMounted();
  const {
    video,
    isSVP,
    depIsPassportMember,
    stationData,
    profileStub,
    displayAsPassport = false
  } = props;
  const [showVideo, setShowVideo] = useState(false);
  const [profile] = useAtom(userProfile); // eslint-disable-line react-hooks/rules-of-hooks
  // Checks if component is mounted on the client before attempting to
  // hydrate, otherwise a hydration error occurs
  if (!hasMounted) {
    return null;
  }
  const links = video.hero_links;
  const hasRelatedAsset = video.related_episode_asset ? true : false;
  const getIsPassportMember = () => {
    const profileOrStub = profileStub ? profileStub : profile;
    const profileIsPassport = profileOrStub?.personal_data?.is_passport;
    // did we pass the depIsPassportMember prop (e.g. from storybook or tests)
    return depIsPassportMember ||
    // are they is_passport
    profileIsPassport;
  };
  if (!stationData) {
    var short_common_name = 'PBS';
    var call_sign = '';
    var passport_url = '';
  } else {
    var {
      attributes: station_info
    } = stationData;
    var {
      short_common_name,
      call_sign,
      passport_url
    } = station_info;
  }
  const isPassportMember = getIsPassportMember();
  const isPassportMemberOfLocalizedStation = profile?.personal_data?.stations?.includes(call_sign) && isPassportMember;
  const isPassportVideo = displayAsPassport ? true : video.flags?.is_mvod;
  var passPortCalloutText: string | null = `Watch this video with ${short_common_name} Passport`;
  switch (true) {
    case isPassportMemberOfLocalizedStation:
      passPortCalloutText = `Available with your ${short_common_name} Passport benefit`;
      break;
    case isPassportMember:
      passPortCalloutText = `Available with your PBS Passport benefit`;
      break;
  }
  const donateUrl = passport_url ? passport_url : '/donate';
  let isDonateAndStartWatchingCTA: boolean = false;

  // Show a Donate CTA when the user is not a Passport Member and it is a Passport Video
  if (!isPassportMember && isPassportVideo) {
    isDonateAndStartWatchingCTA = true;
  }

  // Instead of using video.summary, we explicitly create metadata with the "shortEpOnly" format
  // so that the video's run time is excluded
  const videoMeta = formatVideoMetadata(video, "shortEpOnly");
  let videoDescription = video.description_short;
  if (videoMeta && videoMeta !== '') {
    videoDescription = `${videoMeta} | ` + videoDescription;
  }
  const ShowLogoOrTitle = () => {
    const ancestorWhiteLogo = video.ancestor_white_logo;
    if (!video.show) {
      return null;
    }
    return <>
        {video.show && ancestorWhiteLogo ? <h2 className={styles.video_show_logo}>
            <Link href={`/show/${video.show.slug}/`}>
              <ITSImage src={ancestorWhiteLogo} alt={video.show.title} width={240} />
            </Link>
          </h2> : <h2 className={styles.video_show_overtitle}>
            <Link href={`/show/${video.show.slug}/`}>
              {video.show.title}
            </Link>
          </h2>}
      </>;
  };
  const VideoHeroKabobMenuItems = () => <ul data-sentry-component="VideoHeroKabobMenuItems" data-sentry-source-file="VideoHero.tsx">
      <li>
        <MyListButton style='kabobMenu' video={video} data-sentry-element="MyListButton" data-sentry-source-file="VideoHero.tsx" />
      </li>
      {video.show && <li className={styles.video_hero_kabob_item_explore_the_show}>
          <Link href={`/show/${video.show.slug}/`}>
            <PlayIcon />
            <span>Explore the Show</span>
          </Link>
        </li>}
      {links && links.length > 0 && links.map((link, index) => {
      return <li className={styles.video_hero_kabob_item_link} key={index}>
              {link.url && <Link href={link.url} target="_blank">
                <ArrowRightIcon />
                <span>{link.text}</span>
              </Link>}
            </li>;
    })}
    </ul>;
  const VideoHeroPassportCallout = () => <p className={styles.video_passport_callout} data-sentry-component="VideoHeroPassportCallout" data-sentry-source-file="VideoHero.tsx">
      <CompassRose className={styles.compass_rose_icon} data-sentry-element="CompassRose" data-sentry-source-file="VideoHero.tsx" />
      {passPortCalloutText}
    </p>;
  const VideoHeroPrimaryCTAButton = () => isDonateAndStartWatchingCTA ? <LinkButton href={donateUrl} style="white">
        Donate & Start Watching
      </LinkButton> : <LinkButton href={`/video/${video.slug}/`} style="white" iconBefore="play">
        Watch Now
      </LinkButton>;
  const VideoHeroOverlay = (props: {
    isLoaded: boolean;
  }) => {
    const {
      isLoaded
    } = props;
    return <div className={isLoaded ? styles.video_hero_loaded_overlay : styles.video_hero_initial_overlay} data-sentry-component="VideoHeroOverlay" data-sentry-source-file="VideoHero.tsx">
        <ShowLogoOrTitle data-sentry-element="ShowLogoOrTitle" data-sentry-source-file="VideoHero.tsx" />
        <h3 className={styles.video_title}>
          <Link href={`/video/${video.slug}/`} data-sentry-element="Link" data-sentry-source-file="VideoHero.tsx">
            {video.title}
          </Link>
        </h3>
        <p className={styles.video_description}>
          {videoDescription}
        </p>
        {isPassportVideo && stationData && <VideoHeroPassportCallout />}
        <div className={isLoaded ? styles.video_hero_loaded_overlay_buttons : styles.video_hero_initial_overlay_buttons}>
          <VideoHeroPrimaryCTAButton data-sentry-element="VideoHeroPrimaryCTAButton" data-sentry-source-file="VideoHero.tsx" />

          {video.show && <LinkButton href={`/show/${video.show.slug}/`} style="white_ghost" className={styles.video_hero_explore_the_show_button}>
              Explore the Show
            </LinkButton>}

          <KabobMenu className={styles.video_hero_kabob_button_above_sm} contentClassName={styles.video_hero_kabob_content_above_sm} data-sentry-element="KabobMenu" data-sentry-source-file="VideoHero.tsx">
            <VideoHeroKabobMenuItems data-sentry-element="VideoHeroKabobMenuItems" data-sentry-source-file="VideoHero.tsx" />
          </KabobMenu>
        </div>
        <KabobMenu className={styles.video_hero_kabob_button_below_sm} contentClassName={styles.video_hero_kabob_content_below_sm} data-sentry-element="KabobMenu" data-sentry-source-file="VideoHero.tsx">
          <VideoHeroKabobMenuItems data-sentry-element="VideoHeroKabobMenuItems" data-sentry-source-file="VideoHero.tsx" />
        </KabobMenu>
      </div>;
  };
  const InitialVideoHero = () => {
    return <div className={styles.video_hero_initial} data-sentry-component="InitialVideoHero" data-sentry-source-file="VideoHero.tsx">
      {/* RWEB-8182 - we want to use the thumbnail of the curated video, but play the related asset (usually a preview) */}
        <MezzanineVideoInitializer videoType={video.related_episode_asset?.video_type || VideoTypeEnum.Video} imgSrc={video.image} alt={video.title} showWatchButton={hasRelatedAsset} onClick={() => setShowVideo(true)} verticalOffsetButton={true} width={1440} className={styles.video_hero_video_initializer} data-sentry-element="MezzanineVideoInitializer" data-sentry-source-file="VideoHero.tsx" />
        <VideoHeroOverlay isLoaded={false} data-sentry-element="VideoHeroOverlay" data-sentry-source-file="VideoHero.tsx" />
      </div>;
  };
  const VideoPlayerLoaded = () => {
    const playerConfig = {
      autoplay: true,
      embedType: isSVP ? 'stationplayer' : 'portalplayer',
      disableContinuousPlay: true,
      previewLayout: true,
      stationId: stationData?.id,
      callsign: stationData?.attributes.call_sign
    };
    return <div className={styles.video_hero_loaded} data-sentry-component="VideoPlayerLoaded" data-sentry-source-file="VideoHero.tsx">
        <VideoPlayer video={video.related_episode_asset!} playerConfig={playerConfig} className={styles.video_hero_loaded_video_player} data-sentry-element="VideoPlayer" data-sentry-source-file="VideoHero.tsx" />

        <VideoHeroOverlay isLoaded={true} data-sentry-element="VideoHeroOverlay" data-sentry-source-file="VideoHero.tsx" />
      </div>;
  };
  return showVideo && hasRelatedAsset ? <section data-sentry-component="VideoHero" data-sentry-source-file="VideoHero.tsx">
        <VideoPlayerLoaded data-sentry-element="VideoPlayerLoaded" data-sentry-source-file="VideoHero.tsx" />
      </section> : <section data-sentry-component="VideoHero" data-sentry-source-file="VideoHero.tsx">
        <InitialVideoHero data-sentry-element="InitialVideoHero" data-sentry-source-file="VideoHero.tsx" />
      </section>;
};
export default VideoHero;